import { defineMessages } from 'react-intl';

export default defineMessages({
	header: {
		id: 'stripePaymentElementForm.header',
		defaultMessage: 'Pay With Credit Card',
		description: 'The heading above the form where credit card details are entered.',
	},
	billingHeader: {
		id: 'stripePaymentElementForm.billingHeader',
		defaultMessage: 'Billing Details',
		description: 'The billing details heading above the form where address details are entered.',
	},
});
