import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { EnvConfig } from '@/env_config';
import { useActionPlans } from '@/hooks/api/useActionPlan';
import { useFeatureFlags, FeatureFlagNames } from '@/hooks/api/useFeatureFlags';
import { useOptionalPartner } from '@/hooks/api/usePartner';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { useUser } from '@/hooks/store';
import { useLocation } from '@/hooks/useLocation';
import { ActionPlanItem } from '@/types/actionPlan';
import { IntegrationType, PartnerCategoryType } from '@/types/store/reducers';
import { isSelfServePlan } from '@/utils/SkuUtils';
import { appcuesGroup, appcuesIdentify } from '@/utils/analytics/appcues';

export const B2B_APPCUES_ENABLED = 'b2b_appcues_enabled';
const { B2B_PARTNER_HOMEPAGE } = FeatureFlagNames;

const allowedPartnerCategories: Partial<Record<PartnerCategoryType, boolean>> = {
	[PartnerCategoryType.SALES_TRIAL]: true,
	[PartnerCategoryType.CALM_FOR_BUSINESS]: true,
};

const EXCLUDED_PATHS = [
	'/teams.*',
	'/login',
	'/signup',
	'/trial-signup',
	'/lead-trial-signup',
	'/opportunity-trial-signup',
	'/docs/.*',
	'/feedback',
];

function shouldTrack(
	category: PartnerCategoryType,
	isSelfServe: boolean,
	integrationType: IntegrationType,
	pathname: string,
): boolean {
	if (EXCLUDED_PATHS.some(excludedPath => pathname.match(excludedPath))) {
		return false;
	}

	if (category === PartnerCategoryType.SALES_TRIAL) {
		return true;
	}

	if (category !== PartnerCategoryType.CALM_FOR_BUSINESS) {
		return false;
	}

	if (isSelfServe) {
		return true;
	}

	const isEligibilityFile = integrationType === IntegrationType.ELIGIBILITY_FILE;
	if (isEligibilityFile) {
		return true;
	}

	return false;
}

interface AdditionalProps {
	[key: string]: string | boolean;
}

function parseCompletedActions(actionPlans?: ActionPlanItem[]): AdditionalProps {
	if (!actionPlans) {
		return {};
	}

	const completedActions = actionPlans
		.filter(({ state }) => state === 'completed')
		.map(({ event_name }) => event_name);

	const props = completedActions.reduce((previous, name) => {
		return { ...previous, [`${name}-completed`]: true };
	}, {});
	return props;
}

export function useAppcues(): {
	setAdditionalProps: (additionalProps: AdditionalProps) => void;
	isEnabled: boolean;
} {
	const { user } = useUser();
	const { partnerId } = useParams() as { partnerId?: string };
	const partnerData = useOptionalPartner(partnerId);
	const location = useLocation();
	const partner = partnerData?.data;
	const featureFlags = useFeatureFlags(B2B_APPCUES_ENABLED, B2B_PARTNER_HOMEPAGE);
	const { data: actions, loading } = useActionPlans(partnerId || '');
	const [additionalProps, setAdditionalProps] = useState<AdditionalProps>({});
	const [isMobile] = useIsMobile();

	const appcuesEnabledFF =
		!featureFlags.loading && !featureFlags.error && featureFlags.data?.[B2B_APPCUES_ENABLED] === true;
	const homepageEnabledFF =
		!featureFlags.loading && !featureFlags.error && featureFlags.data?.[B2B_PARTNER_HOMEPAGE] === true;

	const isEnabled = useMemo(() => {
		if (
			!EnvConfig.appcuesEnabled ||
			isMobile ||
			loading ||
			!appcuesEnabledFF ||
			!user?.sub ||
			!partner?.id ||
			!partner?.category ||
			!allowedPartnerCategories[partner.category]
		) {
			return false;
		}
		const isSelfServe = isSelfServePlan(partner.vouched_plan_sku);
		if (!shouldTrack(partner.category, isSelfServe, partner.integration_type, location.pathname)) {
			return false;
		}

		return true;
	}, [
		loading,
		appcuesEnabledFF,
		isMobile,
		partner?.category,
		partner?.id,
		partner?.integration_type,
		partner?.vouched_plan_sku,
		user?.sub,
		location.pathname,
	]);

	useEffect(() => {
		async function runAppcuesEffect(): Promise<void> {
			if (!isEnabled || !partner || !user?.sub) {
				return;
			}

			await appcuesIdentify(user.sub, {
				role: user.accessPolicy?.allowed_user_role ?? 'not-set',
				partner_id: partner.id,
			});
			const completedActionsProps = parseCompletedActions(actions?.action_plans);

			const isSelfServe = isSelfServePlan(partner.vouched_plan_sku);

			await appcuesGroup(partner.id, {
				category: partner.category,
				integration_type: partner.integration_type,
				contract_starts_at: partner.contract_starts_at,
				contract_expires_at: partner.contract_expires_at,
				is_self_serve_plan: isSelfServe,
				is_homepage_enabled: homepageEnabledFF,
				...completedActionsProps,
				...additionalProps,
			});
		}
		runAppcuesEffect().catch(() => {
			// ignore errors
		});
	}, [
		actions,
		isEnabled,
		homepageEnabledFF,
		partner,
		partner?.category,
		partner?.contract_expires_at,
		partner?.contract_starts_at,
		partner?.id,
		partner?.integration_type,
		partner?.vouched_plan_sku,
		user?.accessPolicy?.allowed_user_role,
		user?.sub,
		additionalProps,
	]);

	return { setAdditionalProps, isEnabled };
}
