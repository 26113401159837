/* eslint-disable react/no-danger */

import Script from 'next/script';
import { FC, useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { EnvConfig } from '@/env_config';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useAppcues } from '@/hooks/analytics/useAppcues';
import { logCalmEventProperties } from '@/utils/analytics/calm';

const AppcuesSetup: FC = () => {
	const { isEnabled } = useAppcues();

	if (!isEnabled) {
		return null;
	}

	// This script allows Appcues to detect URL changes and surface the appropriate content to the user based on the new URL.
	// Ref https://studio.appcues.com/settings/installation/guide/script
	//
	// There is an alternative approach outlined here if we want to be more explicit
	// https://studio.appcues.com/settings/installation/guide/page
	return (
		<>
			<Script id="calm-appcues-setup">{`window.AppcuesSettings = { enableURLDetection: true };`}</Script>
			<Script
				id="calm-appcues-dependency"
				src={`//fast.appcues.com/${EnvConfig.appcuesAccountId}.js`}
				async
			></Script>
		</>
	);
};

const InitialPageAnalytics: FC = () => {
	const location = useLocation();
	const pathname = location?.pathname;
	const { logEvent } = useAnalytics();
	const { partnerId } = useParams() as { partnerId?: string };

	useLayoutEffect(() => {
		// Log base event props - like the device id
		logCalmEventProperties();
		// Log the base page view
		const pageName = pathname.replace('/', '').replace(`${partnerId}/`, '');
		logEvent('Page : Viewed', {
			page_name: pageName,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<>
			<AppcuesSetup />
		</>
	);
};

export default InitialPageAnalytics;
