import { EnvConfig } from '@/env_config';
import { JSONObject } from '@/types/json';
import { isCypressLoaded } from '@/utils/cypress';

// Appcues JavaScript API https://docs.appcues.com/article/161-javascript-api
interface Appcues {
	identify: (userId: string, props?: JSONObject) => void;
	group: (groupId: string, props?: JSONObject) => void;
}

declare global {
	interface Window {
		Appcues: Appcues;
	}
}

function isAppcuesLoaded(): boolean {
	return typeof window !== 'undefined' && 'Appcues' in window;
}

const sleep = (milliseconds: number): Promise<void> => {
	return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export async function appcuesIdentify(userId: string, props?: JSONObject): Promise<void> {
	if (!EnvConfig.appcuesEnabled || isCypressLoaded()) return;

	if (!isAppcuesLoaded()) {
		await sleep(500);
		await appcuesIdentify(userId, props);
		return;
	}

	window.Appcues.identify(userId, props);
}

export async function appcuesGroup(groupId: string, props?: JSONObject): Promise<void> {
	if (!EnvConfig.appcuesEnabled || isCypressLoaded()) return;

	if (!isAppcuesLoaded()) {
		await sleep(500);
		await appcuesGroup(groupId, props);
		return;
	}

	window.Appcues.group(groupId, props);
}
